<template>
    <div v-loading="Loading"
         class="warp"
         element-loading-background="rgba(0, 0, 0, 0.8)"
         element-loading-spinner="el-icon-loading"
         element-loading-text="拼命加载中">
        <div class="main">
            <div class="login">
                <Login class="login-ann"></Login>
            </div>

            <div>

                <div :class="'cjdtt'+cjdtt" class="gzbj">
                    <div v-if="cjdtt == 0 || cjdtt == 41" class="title-bj">按[Tab键]切换开启数量</div>
                    <div v-if="cjdtt == 41" class="dcjl">
                        <div class="dcjl-title">
                            <span>最后结果</span>
                        </div>
                        <div class="jl-itme-lis">
                            <div v-for="(itme,index) in hdjl" :key="index" class="jl-itme">
                                <div v-for="(itmec,indexc) in itme" :key="indexc" class="jl-itme-x">
                                    <div :class="'shine'+itmec.type" class="image-container">
                                        <img :src="itmec.ico"
                                             class="jl-itme-x-img">
                                        <span class="jl-itme-itme-num">{{ itmec.num }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dcjl-zf">
                            <span>祝你发大财！</span>
                        </div>
                    </div>

                    <div v-if="cjdtt == 42" class="dcjl-sr">
                        <div class="dcjl-title">
                            <span>最后结果</span>
                        </div>
                        <div class="jl-itme-lis-sr">
                            <div v-for="(itme,index) in hdjl" :key="index" class="jl-itme">
                                <div v-for="(itmec,indexc) in itme" :key="indexc" class="jl-itme-x">
                                    <div :class="'shine'+itmec.type" class="image-container">
                                        <img :src="itmec.ico"
                                             class="jl-itme-x-img">
                                        <span class="jl-itme-itme-num">{{ itmec.num }}</span>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div class="dcjl-zf">
                            <span>祝你发大财！</span>
                        </div>
                    </div>
                </div>
                <div ref="myRadio" class="fuxklx">
                    <div :class="{'xzzho':form.type== 1}" class="ztlx">
                        <el-radio v-model="form.type" :label="1">开启1个</el-radio>
                    </div>
                    <div class="itme-k">
                        <img :src="info.open_item.ico"
                             style="width: 31px;height: 31px">
                        <span class="itme-num">{{info.open_item.num}}</span>
                    </div>
                    <div :class="{'xzzho':form.type== 2}" class="ztlx">
                        <el-radio v-model="form.type" :label="2">开启10个</el-radio>
                    </div>
                </div>
                <div class="ckgl">
                    <span class="glico"></span><span>查看概率</span>
                </div>
                <div class="anntj">
                    <div class="btn" style="margin:0px 0px;display:inline-block;cursor:pointer;" @click="onKq">开 启
                    </div>
                </div>
            </div>
        </div>
        <div class="titlehover">……</div>
        <!-- 弹出框 -->
        <div class="alert">
            <!-- 弹出框 -->
            <div v-if="MessageDisplay" class="alert_bg">
                <div class="alert_main">
                    <div class="alert_title"><span>提示</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <span>{{ MessageMsg }}</span>
                            <div class="btn"
                                 @click="CloseAlert">关闭</div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>

            <div v-if="SuccessDisplay" class="alert_bg">
                <div class="alert_main">
                    <div class="alert_title"><span>提示</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <span>{{ MessageMsg }}</span>
                            <div class="btn"
                                 @click="SuccessAlert">关闭</div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>
            <!-- 弹出框end -->
        </div>
    </div>

</template>
<script>

import Login from '../../components/Login.vue'
import Vue from "vue";

export default {
    name: "Earthen",
    components: {
        Login,
    },
    data() {
        return {
            Loading: false,
            LuckDrawEvents: false,
            MessageDisplay:false,
            SuccessDisplay:false,
            MessageMsg:'',
            cjdtt: 0,
            dynamicKey: 0,
            form: {
                type: 1,
            },
            info: {
                open_item:{
                   itme:'',
                   ico: '',
                   num: 0,
                },
            },
            hdjl: [],
        };
    },
    created() {
        // 监听键盘事件
        document.addEventListener('keyup', this.handleKeyUp);
        this.getInfo();
    },
    beforeDestroy() {
        // 在组件销毁前移除事件监听
        document.removeEventListener('keyup', this.handleKeyUp);
    },
    methods: {
        //获取信息
        getInfo(){
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/earthen/getInfo",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code === 200) {
                        this.info = response.data.info;
                    } else {
                       this.PopMessage(true, response.data.msg);
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        handleKeyUp(event) {
            // 检查是否按下了Tab键
            if (event.key === 'Tab') {
                //如果form.type等于1就改完2 如果是2就改完1
                this.form.type = this.form.type === 1 ? 2 : 1;
            }
        },
        onKq() {
            if(this.Loading === true){
                return false;
            }
            if(this.LuckDrawEvents === true){
                return false;
            }
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/earthen/LuckyDraw",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                    type:this.form.type,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code === 200) {
                        this.hdjl = response.data.rewards;
                        this.LuckDraw();
                    } else {
                        this.PopMessage(false, response.data.msg);
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        //抽奖动画
        LuckDraw() {
            this.LuckDrawEvents = true;
            //使用for循环37次在1开始
            for (let i = 1; i <= 40; i++) {
                //延迟1秒
                setTimeout(() => {
                    this.cjdtt = i;
                    //如果i等于37就显示结果
                    if (i === 40) {
                        this.showResult()
                    }
                }, i * 100);
            }
        },
        //显示结果
        showResult() {
            this.LuckDrawEvents = false;
            //41单抽 42十抽
            if (this.form.type === 1){
                this.cjdtt = 41;
            }else if(this.form.type === 2){
                this.cjdtt = 42;
            }
            this.getInfo()
        },
        //弹出框状态
        PopMessage (type, msg) {
            this.SuccessDisplay = false;
            this.MessageDisplay = false;
            this.MessageMsg = msg;
            if (type === false) {
                this.MessageDisplay = true;
            } else {
                this.SuccessDisplay = true;
            }
        },
        //关闭窗口
        CloseAlert () {
            this.SuccessDisplay = false;
            this.MessageDisplay = false;
        },
        SuccessAlert () {
            this.SuccessDisplay = false;
            this.MessageDisplay = false;
            this.getInfo();
        },
    },
};
</script>


<style scoped>
@import "../../../public/static/plug/earthen/static/css/game.css";
</style>


<style>
#root,
body,
html {
    min-width: 0px;
}
</style>
<style lang="scss" scoped>
/deep/ .el-row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

/deep/ .login-alert_bg{
    top:-295px;
}
</style>

