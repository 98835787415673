<template>
    <div>
        <span>当前角色：</span><span v-if="info.m_id > 0">{{info.charac_name}}</span><a class="logout"
             href="javascript:;"
              @click="LoginDisplay = true">《登录|切换》</a>
        <div v-if="LoginDisplay" v-loading="Loading" :style="'height:'+height" class="login-alert_bg"  element-loading-background="rgba(0, 0, 0, 0.8)"
             element-loading-spinner="el-icon-loading"
             element-loading-text="拼命加载中">
            <div class="login-alert_main"
                 style="width: 350px">
                <div class="alert_title"><span>绑定|切换</span></div>
                <div class="alert_con">
                    <div class="alert_com">
                        <p style="margin-bottom: 6px; display: flex;align-items: center;">
                            <span style="padding: 0px 0px 0px 42px">游戏账号：</span><input v-model="login_info.username" class="service__input" type="text" />
                        </p>
                        <p style="margin-bottom: 6px; display: flex;align-items: center;">
                            <span style="padding: 0px 0px 0px 42px">游戏密码：</span><input v-model="login_info.password" class="service__input" type="password" />
                        </p>
                        <p style="margin-bottom: 6px; display: flex;align-items: center;">
                            <span style="padding: 0 0 0 42px">绑定角色：</span>
                            <select v-model="login_info.role"
                                    style="width: 174px">
                                <option v-for="(itmec, i) in role_data"
                                        :key="i"
                                        :value="itmec.charac_no">
                                    {{ itmec.charac_name }}
                                </option>
                            </select>
                            <span style="background: #7478e4;padding: 3px 10px;margin-left: 2px;border-radius: 4%;" @click="getRole">获取</span>
                        </p>
                        <div>
                  <span style="padding: 0px 0px; color: #f90000"><span style="display: inline-block; padding: 0 0">{{
                          LoginError
                      }}</span></span>
                        </div>
                        <div style="text-align: center; padding: 10px 0">
                            <div class="btn"
                                 style="margin: 0px 0px; display: inline-block"
                                 @click="login">
                                绑定
                            </div>
                            <div class="btn"
                                 style="margin: 0 0; display: inline-block"
                                 @click="LoginDisplay = false">
                                取消
                            </div>
                        </div>
                    </div>
                </div>
                <div class="alert_bottom"><span></span></div>
            </div>
        </div>
    </div>
</template>

<script>
//监听登录组件的登录事件
import Vue from "vue";

export default {
    name: "Login",
    data() {
        return {
            Loading:false,
            LoginDisplay: false,
            LoginError: "",
            login_info: {
                username: "",
                password: "",
                role: "",
            },
            role_data:[],
            info: {
                m_id:0,
                charac_no:0,
                charac_name: '', //当前绑定角色
            },
            height:'',
        }
    },
    mounted() {
        // 获取元素
        var element = document.querySelector('.main');
        // 获取元素的计算样式
        var style = window.getComputedStyle(element);
        // 获取高度
        this.height = style.getPropertyValue('height');

        this.getLoginStatus();
    },
    methods: {
        getRole(){
            if (this.Loading === true) {
                return false;
            }
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/getRole",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                    username: this.login_info.username,
                    password: this.login_info.password,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code === 200) {
                        this.LoginError = "";
                        this.role_data = response.data.info;
                        if(this.role_data.length > 0){
                            this.login_info.role = this.role_data[0].charac_no;
                        }
                    } else {
                        this.LoginError = response.data.msg;
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        login(){
            if (this.Loading === true) {
                return false;
            }
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/PlugLogin",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                    username: this.login_info.username,
                    password: this.login_info.password,
                    role: this.login_info.role,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code === 200) {
                        this.LoginDisplay = false;
                        this.getLoginStatus();
                    } else {
                        this.LoginError = response.data.msg;
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        //获取登录状态
        getLoginStatus() {
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/getLoginInfo",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                },
                responseType: "json",
            })
                .then((response) => {
                    if (response.data.code === 200) {
                        this.info = response.data.info;
                        if(this.info.m_id <= 0){
                            this.LoginDisplay = true;
                        }
                    }
                })
                .catch((error) => {
                    // 请求失败，
                    console.log(error);
                });
        }
    }
}
</script>


<style scoped>
.login-alert_bg {
    width: 100%;
    position: absolute;
    top: 0;
    color: #fff;
    z-index: 10;
    background: #000000a8;
    /*定位到指定class中*/
    /*上下左右居中*/
    display: flex;
}

.login-alert_main {
    width: 300px;
    text-align: center;
    position: relative;
    /*上下左右居中*/
    margin: 0 auto;
    top: 20%;
}

.alert_com {
    width: 100%;
    display: block;
    float: left;
    padding: 15px 0 0;
    border: 1px solid #fff3
}

.alert_com span {
    padding: 0 20px;
    display: block
}

.alert_com hr {
    margin-bottom: 10px;
    border: none;
    border-bottom: 1px solid #fff3
}

.alert_com input, .alert_com select {
    width: 200px;
    position: relative;
    height: 24px;
    line-height: 24px;
    background: #000;
    color: #b59758;
    padding: 0 5px;
    border: 1px solid #b59758;
    box-sizing: border-box;
    color: #fff;
    font-family: simsun
}
</style>